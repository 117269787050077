import React, { Component } from "react"
import Layout from "../components/Layout"

export default class stats extends Component {
  constructor(props) {
    super(props)

    this.state = {
      statlist: [
        "animalsBred",
        "aviateOneCm",
        "bannerCleaned",
        "beaconInteraction",
        "boatOneCm",
        "breakItem.minecraft.bow",
        "breakItem.minecraft.chainmail_boots",
        "breakItem.minecraft.chainmail_chestplate",
        "breakItem.minecraft.chainmail_helmet",
        "breakItem.minecraft.chainmail_leggings",
        "breakItem.minecraft.diamond_axe",
        "breakItem.minecraft.diamond_boots",
        "breakItem.minecraft.diamond_chestplate",
        "breakItem.minecraft.diamond_helmet",
        "breakItem.minecraft.diamond_hoe",
        "breakItem.minecraft.diamond_leggings",
        "breakItem.minecraft.diamond_pickaxe",
        "breakItem.minecraft.diamond_shovel",
        "breakItem.minecraft.diamond_sword",
        "breakItem.minecraft.fishing_rod",
        "breakItem.minecraft.flint_and_steel",
        "breakItem.minecraft.golden_axe",
        "breakItem.minecraft.golden_boots",
        "breakItem.minecraft.golden_chestplate",
        "breakItem.minecraft.golden_helmet",
        "breakItem.minecraft.golden_hoe",
        "breakItem.minecraft.golden_leggings",
        "breakItem.minecraft.golden_pickaxe",
        "breakItem.minecraft.golden_shovel",
        "breakItem.minecraft.golden_sword",
        "breakItem.minecraft.iron_axe",
        "breakItem.minecraft.iron_boots",
        "breakItem.minecraft.iron_chestplate",
        "breakItem.minecraft.iron_helmet",
        "breakItem.minecraft.iron_hoe",
        "breakItem.minecraft.iron_leggings",
        "breakItem.minecraft.iron_pickaxe",
        "breakItem.minecraft.iron_shovel",
        "breakItem.minecraft.iron_sword",
        "breakItem.minecraft.leather_boots",
        "breakItem.minecraft.leather_chestplate",
        "breakItem.minecraft.leather_helmet",
        "breakItem.minecraft.leather_leggings",
        "breakItem.minecraft.shears",
        "breakItem.minecraft.shield",
        "breakItem.minecraft.stone_axe",
        "breakItem.minecraft.stone_hoe",
        "breakItem.minecraft.stone_pickaxe",
        "breakItem.minecraft.stone_shovel",
        "breakItem.minecraft.stone_sword",
        "breakItem.minecraft.wooden_axe",
        "breakItem.minecraft.wooden_hoe",
        "breakItem.minecraft.wooden_pickaxe",
        "breakItem.minecraft.wooden_shovel",
        "breakItem.minecraft.wooden_sword",
        "brewingstandInteraction",
        "cakeSlicesEaten",
        "cauldronFilled",
        "cauldronUsed",
        "chestOpened",
        "climbOneCm",
        "craftItem.minecraft.acacia_boat",
        "craftItem.minecraft.acacia_door",
        "craftItem.minecraft.acacia_fence",
        "craftItem.minecraft.acacia_fence_gate",
        "craftItem.minecraft.acacia_stairs",
        "craftItem.minecraft.activator_rail",
        "craftItem.minecraft.anvil",
        "craftItem.minecraft.armor_stand",
        "craftItem.minecraft.arrow",
        "craftItem.minecraft.baked_potato",
        "craftItem.minecraft.banner",
        "craftItem.minecraft.beacon",
        "craftItem.minecraft.bed",
        "craftItem.minecraft.beetroot_soup",
        "craftItem.minecraft.birch_boat",
        "craftItem.minecraft.birch_door",
        "craftItem.minecraft.birch_fence",
        "craftItem.minecraft.birch_fence_gate",
        "craftItem.minecraft.birch_stairs",
        "craftItem.minecraft.black_glazed_terracotta",
        "craftItem.minecraft.blaze_powder",
        "craftItem.minecraft.blue_glazed_terracotta",
        "craftItem.minecraft.boat",
        "craftItem.minecraft.bone_block",
        "craftItem.minecraft.book",
        "craftItem.minecraft.bookshelf",
        "craftItem.minecraft.bow",
        "craftItem.minecraft.bowl",
        "craftItem.minecraft.bread",
        "craftItem.minecraft.brewing_stand",
        "craftItem.minecraft.brick",
        "craftItem.minecraft.brick_block",
        "craftItem.minecraft.brick_stairs",
        "craftItem.minecraft.brown_glazed_terracotta",
        "craftItem.minecraft.bucket",
        "craftItem.minecraft.cake",
        "craftItem.minecraft.carpet",
        "craftItem.minecraft.carrot_on_a_stick",
        "craftItem.minecraft.cauldron",
        "craftItem.minecraft.chest",
        "craftItem.minecraft.chest_minecart",
        "craftItem.minecraft.chorus_fruit_popped",
        "craftItem.minecraft.clay",
        "craftItem.minecraft.clock",
        "craftItem.minecraft.coal",
        "craftItem.minecraft.coal_block",
        "craftItem.minecraft.cobblestone_wall",
        "craftItem.minecraft.comparator",
        "craftItem.minecraft.compass",
        "craftItem.minecraft.concrete_powder",
        "craftItem.minecraft.cooked_beef",
        "craftItem.minecraft.cooked_chicken",
        "craftItem.minecraft.cooked_fish",
        "craftItem.minecraft.cooked_mutton",
        "craftItem.minecraft.cooked_porkchop",
        "craftItem.minecraft.cooked_rabbit",
        "craftItem.minecraft.cookie",
        "craftItem.minecraft.crafting_table",
        "craftItem.minecraft.cyan_glazed_terracotta",
        "craftItem.minecraft.dark_oak_boat",
        "craftItem.minecraft.dark_oak_door",
        "craftItem.minecraft.dark_oak_fence",
        "craftItem.minecraft.dark_oak_fence_gate",
        "craftItem.minecraft.dark_oak_stairs",
        "craftItem.minecraft.daylight_detector",
        "craftItem.minecraft.detector_rail",
        "craftItem.minecraft.diamond",
        "craftItem.minecraft.diamond_axe",
        "craftItem.minecraft.diamond_block",
        "craftItem.minecraft.diamond_boots",
        "craftItem.minecraft.diamond_chestplate",
        "craftItem.minecraft.diamond_helmet",
        "craftItem.minecraft.diamond_hoe",
        "craftItem.minecraft.diamond_leggings",
        "craftItem.minecraft.diamond_pickaxe",
        "craftItem.minecraft.diamond_shovel",
        "craftItem.minecraft.diamond_sword",
        "craftItem.minecraft.dirt",
        "craftItem.minecraft.dispenser",
        "craftItem.minecraft.dropper",
        "craftItem.minecraft.dye",
        "craftItem.minecraft.emerald",
        "craftItem.minecraft.emerald_block",
        "craftItem.minecraft.enchanting_table",
        "craftItem.minecraft.end_bricks",
        "craftItem.minecraft.end_crystal",
        "craftItem.minecraft.end_rod",
        "craftItem.minecraft.ender_chest",
        "craftItem.minecraft.ender_eye",
        "craftItem.minecraft.fence",
        "craftItem.minecraft.fence_gate",
        "craftItem.minecraft.fermented_spider_eye",
        "craftItem.minecraft.fire_charge",
        "craftItem.minecraft.fishing_rod",
        "craftItem.minecraft.flint_and_steel",
        "craftItem.minecraft.flower_pot",
        "craftItem.minecraft.furnace",
        "craftItem.minecraft.furnace_minecart",
        "craftItem.minecraft.glass",
        "craftItem.minecraft.glass_bottle",
        "craftItem.minecraft.glass_pane",
        "craftItem.minecraft.glowstone",
        "craftItem.minecraft.gold_block",
        "craftItem.minecraft.gold_ingot",
        "craftItem.minecraft.gold_nugget",
        "craftItem.minecraft.golden_apple",
        "craftItem.minecraft.golden_axe",
        "craftItem.minecraft.golden_boots",
        "craftItem.minecraft.golden_carrot",
        "craftItem.minecraft.golden_chestplate",
        "craftItem.minecraft.golden_helmet",
        "craftItem.minecraft.golden_hoe",
        "craftItem.minecraft.golden_leggings",
        "craftItem.minecraft.golden_pickaxe",
        "craftItem.minecraft.golden_rail",
        "craftItem.minecraft.golden_shovel",
        "craftItem.minecraft.golden_sword",
        "craftItem.minecraft.gray_glazed_terracotta",
        "craftItem.minecraft.green_glazed_terracotta",
        "craftItem.minecraft.hardened_clay",
        "craftItem.minecraft.hay_block",
        "craftItem.minecraft.heavy_weighted_pressure_plate",
        "craftItem.minecraft.hopper",
        "craftItem.minecraft.hopper_minecart",
        "craftItem.minecraft.iron_axe",
        "craftItem.minecraft.iron_bars",
        "craftItem.minecraft.iron_block",
        "craftItem.minecraft.iron_boots",
        "craftItem.minecraft.iron_chestplate",
        "craftItem.minecraft.iron_door",
        "craftItem.minecraft.iron_helmet",
        "craftItem.minecraft.iron_hoe",
        "craftItem.minecraft.iron_ingot",
        "craftItem.minecraft.iron_leggings",
        "craftItem.minecraft.iron_nugget",
        "craftItem.minecraft.iron_pickaxe",
        "craftItem.minecraft.iron_shovel",
        "craftItem.minecraft.iron_sword",
        "craftItem.minecraft.iron_trapdoor",
        "craftItem.minecraft.item_frame",
        "craftItem.minecraft.jukebox",
        "craftItem.minecraft.jungle_boat",
        "craftItem.minecraft.jungle_door",
        "craftItem.minecraft.jungle_fence",
        "craftItem.minecraft.jungle_fence_gate",
        "craftItem.minecraft.jungle_stairs",
        "craftItem.minecraft.ladder",
        "craftItem.minecraft.lapis_block",
        "craftItem.minecraft.lead",
        "craftItem.minecraft.leather",
        "craftItem.minecraft.leather_boots",
        "craftItem.minecraft.leather_chestplate",
        "craftItem.minecraft.leather_helmet",
        "craftItem.minecraft.leather_leggings",
        "craftItem.minecraft.lever",
        "craftItem.minecraft.light_blue_glazed_terracotta",
        "craftItem.minecraft.light_weighted_pressure_plate",
        "craftItem.minecraft.lime_glazed_terracotta",
        "craftItem.minecraft.lit_pumpkin",
        "craftItem.minecraft.magenta_glazed_terracotta",
        "craftItem.minecraft.magma_cream",
        "craftItem.minecraft.map",
        "craftItem.minecraft.melon_block",
        "craftItem.minecraft.melon_seeds",
        "craftItem.minecraft.minecart",
        "craftItem.minecraft.mossy_cobblestone",
        "craftItem.minecraft.mushroom_stew",
        "craftItem.minecraft.nether_brick",
        "craftItem.minecraft.nether_brick_fence",
        "craftItem.minecraft.nether_brick_stairs",
        "craftItem.minecraft.nether_wart_block",
        "craftItem.minecraft.netherbrick",
        "craftItem.minecraft.noteblock",
        "craftItem.minecraft.oak_stairs",
        "craftItem.minecraft.observer",
        "craftItem.minecraft.orange_glazed_terracotta",
        "craftItem.minecraft.painting",
        "craftItem.minecraft.paper",
        "craftItem.minecraft.pink_glazed_terracotta",
        "craftItem.minecraft.piston",
        "craftItem.minecraft.planks",
        "craftItem.minecraft.prismarine",
        "craftItem.minecraft.pumpkin_pie",
        "craftItem.minecraft.pumpkin_seeds",
        "craftItem.minecraft.purple_glazed_terracotta",
        "craftItem.minecraft.purple_shulker_box",
        "craftItem.minecraft.purpur_block",
        "craftItem.minecraft.purpur_pillar",
        "craftItem.minecraft.purpur_slab",
        "craftItem.minecraft.purpur_stairs",
        "craftItem.minecraft.quartz",
        "craftItem.minecraft.quartz_block",
        "craftItem.minecraft.quartz_stairs",
        "craftItem.minecraft.rabbit_stew",
        "craftItem.minecraft.rail",
        "craftItem.minecraft.red_glazed_terracotta",
        "craftItem.minecraft.red_nether_brick",
        "craftItem.minecraft.red_sandstone",
        "craftItem.minecraft.red_sandstone_stairs",
        "craftItem.minecraft.redstone",
        "craftItem.minecraft.redstone_block",
        "craftItem.minecraft.redstone_lamp",
        "craftItem.minecraft.redstone_torch",
        "craftItem.minecraft.repeater",
        "craftItem.minecraft.sandstone",
        "craftItem.minecraft.sandstone_stairs",
        "craftItem.minecraft.sea_lantern",
        "craftItem.minecraft.shears",
        "craftItem.minecraft.shield",
        "craftItem.minecraft.sign",
        "craftItem.minecraft.silver_glazed_terracotta",
        "craftItem.minecraft.slime",
        "craftItem.minecraft.slime_ball",
        "craftItem.minecraft.snow",
        "craftItem.minecraft.snow_layer",
        "craftItem.minecraft.speckled_melon",
        "craftItem.minecraft.spectral_arrow",
        "craftItem.minecraft.sponge",
        "craftItem.minecraft.spruce_boat",
        "craftItem.minecraft.spruce_door",
        "craftItem.minecraft.spruce_fence",
        "craftItem.minecraft.spruce_fence_gate",
        "craftItem.minecraft.spruce_stairs",
        "craftItem.minecraft.stained_glass",
        "craftItem.minecraft.stained_glass_pane",
        "craftItem.minecraft.stained_hardened_clay",
        "craftItem.minecraft.stick",
        "craftItem.minecraft.sticky_piston",
        "craftItem.minecraft.stone",
        "craftItem.minecraft.stone_axe",
        "craftItem.minecraft.stone_brick_stairs",
        "craftItem.minecraft.stone_button",
        "craftItem.minecraft.stone_hoe",
        "craftItem.minecraft.stone_pickaxe",
        "craftItem.minecraft.stone_pressure_plate",
        "craftItem.minecraft.stone_shovel",
        "craftItem.minecraft.stone_slab",
        "craftItem.minecraft.stone_slab2",
        "craftItem.minecraft.stone_stairs",
        "craftItem.minecraft.stone_sword",
        "craftItem.minecraft.stonebrick",
        "craftItem.minecraft.sugar",
        "craftItem.minecraft.tnt",
        "craftItem.minecraft.tnt_minecart",
        "craftItem.minecraft.torch",
        "craftItem.minecraft.trapdoor",
        "craftItem.minecraft.trapped_chest",
        "craftItem.minecraft.tripwire_hook",
        "craftItem.minecraft.wheat",
        "craftItem.minecraft.white_glazed_terracotta",
        "craftItem.minecraft.wooden_axe",
        "craftItem.minecraft.wooden_button",
        "craftItem.minecraft.wooden_door",
        "craftItem.minecraft.wooden_hoe",
        "craftItem.minecraft.wooden_pickaxe",
        "craftItem.minecraft.wooden_pressure_plate",
        "craftItem.minecraft.wooden_shovel",
        "craftItem.minecraft.wooden_slab",
        "craftItem.minecraft.wooden_sword",
        "craftItem.minecraft.wool",
        "craftItem.minecraft.writable_book",
        "craftItem.minecraft.yellow_glazed_terracotta",
        "craftingTableInteraction",
        "crouchOneCm",
        "damageDealt",
        "damageTaken",
        "deaths",
        "dispenserInspected",
        "diveOneCm",
        "drop",
        "drop.minecraft.acacia_boat",
        "drop.minecraft.acacia_door",
        "drop.minecraft.acacia_fence",
        "drop.minecraft.acacia_fence_gate",
        "drop.minecraft.acacia_stairs",
        "drop.minecraft.activator_rail",
        "drop.minecraft.anvil",
        "drop.minecraft.apple",
        "drop.minecraft.armor_stand",
        "drop.minecraft.arrow",
        "drop.minecraft.baked_potato",
        "drop.minecraft.banner",
        "drop.minecraft.beacon",
        "drop.minecraft.bed",
        "drop.minecraft.bedrock",
        "drop.minecraft.beef",
        "drop.minecraft.beetroot",
        "drop.minecraft.beetroot_seeds",
        "drop.minecraft.beetroot_soup",
        "drop.minecraft.birch_boat",
        "drop.minecraft.birch_door",
        "drop.minecraft.birch_fence",
        "drop.minecraft.birch_fence_gate",
        "drop.minecraft.birch_stairs",
        "drop.minecraft.black_glazed_terracotta",
        "drop.minecraft.black_shulker_box",
        "drop.minecraft.blaze_powder",
        "drop.minecraft.blaze_rod",
        "drop.minecraft.blue_shulker_box",
        "drop.minecraft.boat",
        "drop.minecraft.bone",
        "drop.minecraft.bone_block",
        "drop.minecraft.book",
        "drop.minecraft.bookshelf",
        "drop.minecraft.bow",
        "drop.minecraft.bowl",
        "drop.minecraft.bread",
        "drop.minecraft.brewing_stand",
        "drop.minecraft.brick",
        "drop.minecraft.brick_block",
        "drop.minecraft.brick_stairs",
        "drop.minecraft.brown_glazed_terracotta",
        "drop.minecraft.brown_mushroom",
        "drop.minecraft.brown_mushroom_block",
        "drop.minecraft.brown_shulker_box",
        "drop.minecraft.bucket",
        "drop.minecraft.cactus",
        "drop.minecraft.cake",
        "drop.minecraft.carpet",
        "drop.minecraft.carrot",
        "drop.minecraft.carrot_on_a_stick",
        "drop.minecraft.cauldron",
        "drop.minecraft.chainmail_boots",
        "drop.minecraft.chainmail_chestplate",
        "drop.minecraft.chainmail_helmet",
        "drop.minecraft.chainmail_leggings",
        "drop.minecraft.chest",
        "drop.minecraft.chest_minecart",
        "drop.minecraft.chicken",
        "drop.minecraft.chorus_flower",
        "drop.minecraft.chorus_fruit",
        "drop.minecraft.chorus_fruit_popped",
        "drop.minecraft.clay",
        "drop.minecraft.clay_ball",
        "drop.minecraft.clock",
        "drop.minecraft.coal",
        "drop.minecraft.coal_block",
        "drop.minecraft.coal_ore",
        "drop.minecraft.cobblestone",
        "drop.minecraft.cobblestone_wall",
        "drop.minecraft.comparator",
        "drop.minecraft.compass",
        "drop.minecraft.concrete",
        "drop.minecraft.concrete_powder",
        "drop.minecraft.cooked_beef",
        "drop.minecraft.cooked_chicken",
        "drop.minecraft.cooked_fish",
        "drop.minecraft.cooked_mutton",
        "drop.minecraft.cooked_porkchop",
        "drop.minecraft.cooked_rabbit",
        "drop.minecraft.cookie",
        "drop.minecraft.crafting_table",
        "drop.minecraft.cyan_shulker_box",
        "drop.minecraft.dark_oak_boat",
        "drop.minecraft.dark_oak_door",
        "drop.minecraft.dark_oak_fence",
        "drop.minecraft.dark_oak_fence_gate",
        "drop.minecraft.dark_oak_stairs",
        "drop.minecraft.daylight_detector",
        "drop.minecraft.deadbush",
        "drop.minecraft.detector_rail",
        "drop.minecraft.diamond",
        "drop.minecraft.diamond_axe",
        "drop.minecraft.diamond_block",
        "drop.minecraft.diamond_boots",
        "drop.minecraft.diamond_chestplate",
        "drop.minecraft.diamond_helmet",
        "drop.minecraft.diamond_hoe",
        "drop.minecraft.diamond_horse_armor",
        "drop.minecraft.diamond_leggings",
        "drop.minecraft.diamond_ore",
        "drop.minecraft.diamond_pickaxe",
        "drop.minecraft.diamond_shovel",
        "drop.minecraft.diamond_sword",
        "drop.minecraft.dirt",
        "drop.minecraft.dispenser",
        "drop.minecraft.double_plant",
        "drop.minecraft.dragon_breath",
        "drop.minecraft.dragon_egg",
        "drop.minecraft.dropper",
        "drop.minecraft.dye",
        "drop.minecraft.egg",
        "drop.minecraft.elytra",
        "drop.minecraft.emerald",
        "drop.minecraft.emerald_block",
        "drop.minecraft.emerald_ore",
        "drop.minecraft.enchanted_book",
        "drop.minecraft.enchanting_table",
        "drop.minecraft.end_bricks",
        "drop.minecraft.end_crystal",
        "drop.minecraft.end_rod",
        "drop.minecraft.end_stone",
        "drop.minecraft.ender_chest",
        "drop.minecraft.ender_eye",
        "drop.minecraft.ender_pearl",
        "drop.minecraft.experience_bottle",
        "drop.minecraft.feather",
        "drop.minecraft.fence",
        "drop.minecraft.fence_gate",
        "drop.minecraft.fermented_spider_eye",
        "drop.minecraft.filled_map",
        "drop.minecraft.fire_charge",
        "drop.minecraft.fireworks",
        "drop.minecraft.fish",
        "drop.minecraft.fishing_rod",
        "drop.minecraft.flint",
        "drop.minecraft.flint_and_steel",
        "drop.minecraft.flower_pot",
        "drop.minecraft.furnace",
        "drop.minecraft.furnace_minecart",
        "drop.minecraft.ghast_tear",
        "drop.minecraft.glass",
        "drop.minecraft.glass_bottle",
        "drop.minecraft.glass_pane",
        "drop.minecraft.glowstone",
        "drop.minecraft.glowstone_dust",
        "drop.minecraft.gold_block",
        "drop.minecraft.gold_ingot",
        "drop.minecraft.gold_nugget",
        "drop.minecraft.gold_ore",
        "drop.minecraft.golden_apple",
        "drop.minecraft.golden_axe",
        "drop.minecraft.golden_boots",
        "drop.minecraft.golden_carrot",
        "drop.minecraft.golden_chestplate",
        "drop.minecraft.golden_helmet",
        "drop.minecraft.golden_hoe",
        "drop.minecraft.golden_horse_armor",
        "drop.minecraft.golden_leggings",
        "drop.minecraft.golden_pickaxe",
        "drop.minecraft.golden_rail",
        "drop.minecraft.golden_shovel",
        "drop.minecraft.golden_sword",
        "drop.minecraft.grass",
        "drop.minecraft.gravel",
        "drop.minecraft.gray_glazed_terracotta",
        "drop.minecraft.gray_shulker_box",
        "drop.minecraft.green_shulker_box",
        "drop.minecraft.gunpowder",
        "drop.minecraft.hardened_clay",
        "drop.minecraft.hay_block",
        "drop.minecraft.heavy_weighted_pressure_plate",
        "drop.minecraft.hopper",
        "drop.minecraft.hopper_minecart",
        "drop.minecraft.ice",
        "drop.minecraft.iron_axe",
        "drop.minecraft.iron_bars",
        "drop.minecraft.iron_block",
        "drop.minecraft.iron_boots",
        "drop.minecraft.iron_chestplate",
        "drop.minecraft.iron_door",
        "drop.minecraft.iron_helmet",
        "drop.minecraft.iron_hoe",
        "drop.minecraft.iron_horse_armor",
        "drop.minecraft.iron_ingot",
        "drop.minecraft.iron_leggings",
        "drop.minecraft.iron_nugget",
        "drop.minecraft.iron_ore",
        "drop.minecraft.iron_pickaxe",
        "drop.minecraft.iron_shovel",
        "drop.minecraft.iron_sword",
        "drop.minecraft.iron_trapdoor",
        "drop.minecraft.item_frame",
        "drop.minecraft.jukebox",
        "drop.minecraft.jungle_boat",
        "drop.minecraft.jungle_door",
        "drop.minecraft.jungle_fence",
        "drop.minecraft.jungle_fence_gate",
        "drop.minecraft.jungle_stairs",
        "drop.minecraft.ladder",
        "drop.minecraft.lapis_block",
        "drop.minecraft.lapis_ore",
        "drop.minecraft.lava_bucket",
        "drop.minecraft.lead",
        "drop.minecraft.leather",
        "drop.minecraft.leather_boots",
        "drop.minecraft.leather_chestplate",
        "drop.minecraft.leather_helmet",
        "drop.minecraft.leather_leggings",
        "drop.minecraft.leaves",
        "drop.minecraft.leaves2",
        "drop.minecraft.lever",
        "drop.minecraft.light_blue_shulker_box",
        "drop.minecraft.light_weighted_pressure_plate",
        "drop.minecraft.lime_shulker_box",
        "drop.minecraft.lit_pumpkin",
        "drop.minecraft.log",
        "drop.minecraft.log2",
        "drop.minecraft.magenta_shulker_box",
        "drop.minecraft.magma",
        "drop.minecraft.magma_cream",
        "drop.minecraft.map",
        "drop.minecraft.melon",
        "drop.minecraft.melon_block",
        "drop.minecraft.melon_seeds",
        "drop.minecraft.milk_bucket",
        "drop.minecraft.minecart",
        "drop.minecraft.mossy_cobblestone",
        "drop.minecraft.mushroom_stew",
        "drop.minecraft.mutton",
        "drop.minecraft.mycelium",
        "drop.minecraft.name_tag",
        "drop.minecraft.nether_brick",
        "drop.minecraft.nether_brick_fence",
        "drop.minecraft.nether_brick_stairs",
        "drop.minecraft.nether_star",
        "drop.minecraft.nether_wart",
        "drop.minecraft.nether_wart_block",
        "drop.minecraft.netherbrick",
        "drop.minecraft.netherrack",
        "drop.minecraft.noteblock",
        "drop.minecraft.oak_stairs",
        "drop.minecraft.observer",
        "drop.minecraft.obsidian",
        "drop.minecraft.orange_shulker_box",
        "drop.minecraft.packed_ice",
        "drop.minecraft.painting",
        "drop.minecraft.paper",
        "drop.minecraft.pink_glazed_terracotta",
        "drop.minecraft.pink_shulker_box",
        "drop.minecraft.piston",
        "drop.minecraft.planks",
        "drop.minecraft.poisonous_potato",
        "drop.minecraft.porkchop",
        "drop.minecraft.potato",
        "drop.minecraft.potion",
        "drop.minecraft.prismarine",
        "drop.minecraft.prismarine_crystals",
        "drop.minecraft.prismarine_shard",
        "drop.minecraft.pumpkin",
        "drop.minecraft.pumpkin_pie",
        "drop.minecraft.pumpkin_seeds",
        "drop.minecraft.purple_shulker_box",
        "drop.minecraft.purpur_block",
        "drop.minecraft.purpur_pillar",
        "drop.minecraft.purpur_slab",
        "drop.minecraft.purpur_stairs",
        "drop.minecraft.quartz",
        "drop.minecraft.quartz_block",
        "drop.minecraft.quartz_ore",
        "drop.minecraft.quartz_stairs",
        "drop.minecraft.rabbit",
        "drop.minecraft.rabbit_foot",
        "drop.minecraft.rabbit_hide",
        "drop.minecraft.rail",
        "drop.minecraft.record_11",
        "drop.minecraft.record_13",
        "drop.minecraft.record_blocks",
        "drop.minecraft.record_cat",
        "drop.minecraft.record_far",
        "drop.minecraft.record_mall",
        "drop.minecraft.record_mellohi",
        "drop.minecraft.record_stal",
        "drop.minecraft.record_wait",
        "drop.minecraft.red_flower",
        "drop.minecraft.red_mushroom",
        "drop.minecraft.red_mushroom_block",
        "drop.minecraft.red_nether_brick",
        "drop.minecraft.red_sandstone",
        "drop.minecraft.red_shulker_box",
        "drop.minecraft.redstone",
        "drop.minecraft.redstone_block",
        "drop.minecraft.redstone_lamp",
        "drop.minecraft.redstone_ore",
        "drop.minecraft.redstone_torch",
        "drop.minecraft.reeds",
        "drop.minecraft.repeater",
        "drop.minecraft.rotten_flesh",
        "drop.minecraft.saddle",
        "drop.minecraft.sand",
        "drop.minecraft.sandstone",
        "drop.minecraft.sandstone_stairs",
        "drop.minecraft.sapling",
        "drop.minecraft.sea_lantern",
        "drop.minecraft.shears",
        "drop.minecraft.shield",
        "drop.minecraft.shulker_shell",
        "drop.minecraft.sign",
        "drop.minecraft.silver_glazed_terracotta",
        "drop.minecraft.silver_shulker_box",
        "drop.minecraft.skull",
        "drop.minecraft.slime",
        "drop.minecraft.slime_ball",
        "drop.minecraft.snow",
        "drop.minecraft.snow_layer",
        "drop.minecraft.snowball",
        "drop.minecraft.soul_sand",
        "drop.minecraft.speckled_melon",
        "drop.minecraft.spectral_arrow",
        "drop.minecraft.spider_eye",
        "drop.minecraft.splash_potion",
        "drop.minecraft.sponge",
        "drop.minecraft.spruce_boat",
        "drop.minecraft.spruce_door",
        "drop.minecraft.spruce_fence",
        "drop.minecraft.spruce_fence_gate",
        "drop.minecraft.spruce_stairs",
        "drop.minecraft.stained_glass",
        "drop.minecraft.stained_glass_pane",
        "drop.minecraft.stained_hardened_clay",
        "drop.minecraft.stick",
        "drop.minecraft.sticky_piston",
        "drop.minecraft.stone",
        "drop.minecraft.stone_axe",
        "drop.minecraft.stone_brick_stairs",
        "drop.minecraft.stone_button",
        "drop.minecraft.stone_hoe",
        "drop.minecraft.stone_pickaxe",
        "drop.minecraft.stone_pressure_plate",
        "drop.minecraft.stone_shovel",
        "drop.minecraft.stone_slab",
        "drop.minecraft.stone_stairs",
        "drop.minecraft.stone_sword",
        "drop.minecraft.stonebrick",
        "drop.minecraft.string",
        "drop.minecraft.sugar",
        "drop.minecraft.tallgrass",
        "drop.minecraft.tipped_arrow",
        "drop.minecraft.tnt",
        "drop.minecraft.tnt_minecart",
        "drop.minecraft.torch",
        "drop.minecraft.totem_of_undying",
        "drop.minecraft.trapdoor",
        "drop.minecraft.trapped_chest",
        "drop.minecraft.tripwire_hook",
        "drop.minecraft.vine",
        "drop.minecraft.water_bucket",
        "drop.minecraft.waterlily",
        "drop.minecraft.web",
        "drop.minecraft.wheat",
        "drop.minecraft.wheat_seeds",
        "drop.minecraft.white_glazed_terracotta",
        "drop.minecraft.white_shulker_box",
        "drop.minecraft.wooden_axe",
        "drop.minecraft.wooden_button",
        "drop.minecraft.wooden_door",
        "drop.minecraft.wooden_hoe",
        "drop.minecraft.wooden_pickaxe",
        "drop.minecraft.wooden_pressure_plate",
        "drop.minecraft.wooden_shovel",
        "drop.minecraft.wooden_slab",
        "drop.minecraft.wooden_sword",
        "drop.minecraft.wool",
        "drop.minecraft.writable_book",
        "drop.minecraft.written_book",
        "drop.minecraft.yellow_flower",
        "drop.minecraft.yellow_shulker_box",
        "dropperInspected",
        "enderchestOpened",
        "entityKilledBy.Blaze",
        "entityKilledBy.CaveSpider",
        "entityKilledBy.Creeper",
        "entityKilledBy.ElderGuardian",
        "entityKilledBy.Enderman",
        "entityKilledBy.Endermite",
        "entityKilledBy.EvocationIllager",
        "entityKilledBy.Ghast",
        "entityKilledBy.Guardian",
        "entityKilledBy.Husk",
        "entityKilledBy.LavaSlime",
        "entityKilledBy.Llama",
        "entityKilledBy.PigZombie",
        "entityKilledBy.PolarBear",
        "entityKilledBy.Shulker",
        "entityKilledBy.Silverfish",
        "entityKilledBy.Skeleton",
        "entityKilledBy.Slime",
        "entityKilledBy.Spider",
        "entityKilledBy.Stray",
        "entityKilledBy.Vex",
        "entityKilledBy.VindicationIllager",
        "entityKilledBy.Witch",
        "entityKilledBy.WitherSkeleton",
        "entityKilledBy.Wolf",
        "entityKilledBy.Zombie",
        "entityKilledBy.ZombieVillager",
        "fallOneCm",
        "fishCaught",
        "flowerPotted",
        "furnaceInteraction",
        "hopperInspected",
        "horseOneCm",
        "itemEnchanted",
        "jump",
        "killEntity.Bat",
        "killEntity.Blaze",
        "killEntity.CaveSpider",
        "killEntity.Chicken",
        "killEntity.Cow",
        "killEntity.Creeper",
        "killEntity.Donkey",
        "killEntity.ElderGuardian",
        "killEntity.Enderman",
        "killEntity.Endermite",
        "killEntity.EvocationIllager",
        "killEntity.Ghast",
        "killEntity.Guardian",
        "killEntity.Horse",
        "killEntity.Husk",
        "killEntity.LavaSlime",
        "killEntity.Llama",
        "killEntity.Mule",
        "killEntity.MushroomCow",
        "killEntity.Ozelot",
        "killEntity.Parrot",
        "killEntity.Pig",
        "killEntity.PigZombie",
        "killEntity.PolarBear",
        "killEntity.Rabbit",
        "killEntity.Sheep",
        "killEntity.Shulker",
        "killEntity.Silverfish",
        "killEntity.Skeleton",
        "killEntity.Slime",
        "killEntity.Spider",
        "killEntity.Squid",
        "killEntity.Stray",
        "killEntity.Vex",
        "killEntity.Villager",
        "killEntity.VindicationIllager",
        "killEntity.Witch",
        "killEntity.WitherSkeleton",
        "killEntity.Wolf",
        "killEntity.Zombie",
        "killEntity.ZombieVillager",
        "leaveGame",
        "mineBlock.minecraft.acacia_fence",
        "mineBlock.minecraft.acacia_fence_gate",
        "mineBlock.minecraft.acacia_stairs",
        "mineBlock.minecraft.activator_rail",
        "mineBlock.minecraft.anvil",
        "mineBlock.minecraft.beacon",
        "mineBlock.minecraft.birch_fence",
        "mineBlock.minecraft.birch_fence_gate",
        "mineBlock.minecraft.birch_stairs",
        "mineBlock.minecraft.black_glazed_terracotta",
        "mineBlock.minecraft.blue_glazed_terracotta",
        "mineBlock.minecraft.bone_block",
        "mineBlock.minecraft.bookshelf",
        "mineBlock.minecraft.brick_block",
        "mineBlock.minecraft.brick_stairs",
        "mineBlock.minecraft.brown_glazed_terracotta",
        "mineBlock.minecraft.brown_mushroom",
        "mineBlock.minecraft.brown_mushroom_block",
        "mineBlock.minecraft.cactus",
        "mineBlock.minecraft.carpet",
        "mineBlock.minecraft.chest",
        "mineBlock.minecraft.chorus_flower",
        "mineBlock.minecraft.chorus_plant",
        "mineBlock.minecraft.clay",
        "mineBlock.minecraft.coal_block",
        "mineBlock.minecraft.coal_ore",
        "mineBlock.minecraft.cobblestone",
        "mineBlock.minecraft.cobblestone_wall",
        "mineBlock.minecraft.concrete",
        "mineBlock.minecraft.concrete_powder",
        "mineBlock.minecraft.crafting_table",
        "mineBlock.minecraft.dark_oak_fence",
        "mineBlock.minecraft.dark_oak_fence_gate",
        "mineBlock.minecraft.dark_oak_stairs",
        "mineBlock.minecraft.daylight_detector",
        "mineBlock.minecraft.deadbush",
        "mineBlock.minecraft.detector_rail",
        "mineBlock.minecraft.diamond_block",
        "mineBlock.minecraft.diamond_ore",
        "mineBlock.minecraft.dirt",
        "mineBlock.minecraft.dispenser",
        "mineBlock.minecraft.double_plant",
        "mineBlock.minecraft.dropper",
        "mineBlock.minecraft.emerald_block",
        "mineBlock.minecraft.emerald_ore",
        "mineBlock.minecraft.enchanting_table",
        "mineBlock.minecraft.end_bricks",
        "mineBlock.minecraft.end_rod",
        "mineBlock.minecraft.end_stone",
        "mineBlock.minecraft.ender_chest",
        "mineBlock.minecraft.fence",
        "mineBlock.minecraft.fence_gate",
        "mineBlock.minecraft.furnace",
        "mineBlock.minecraft.glass",
        "mineBlock.minecraft.glass_pane",
        "mineBlock.minecraft.glowstone",
        "mineBlock.minecraft.gold_block",
        "mineBlock.minecraft.gold_ore",
        "mineBlock.minecraft.golden_rail",
        "mineBlock.minecraft.gravel",
        "mineBlock.minecraft.gray_glazed_terracotta",
        "mineBlock.minecraft.hardened_clay",
        "mineBlock.minecraft.hay_block",
        "mineBlock.minecraft.heavy_weighted_pressure_plate",
        "mineBlock.minecraft.hopper",
        "mineBlock.minecraft.ice",
        "mineBlock.minecraft.iron_bars",
        "mineBlock.minecraft.iron_block",
        "mineBlock.minecraft.iron_ore",
        "mineBlock.minecraft.jukebox",
        "mineBlock.minecraft.jungle_fence",
        "mineBlock.minecraft.jungle_fence_gate",
        "mineBlock.minecraft.jungle_stairs",
        "mineBlock.minecraft.ladder",
        "mineBlock.minecraft.lapis_block",
        "mineBlock.minecraft.lapis_ore",
        "mineBlock.minecraft.leaves",
        "mineBlock.minecraft.leaves2",
        "mineBlock.minecraft.lever",
        "mineBlock.minecraft.light_blue_glazed_terracotta",
        "mineBlock.minecraft.light_weighted_pressure_plate",
        "mineBlock.minecraft.log",
        "mineBlock.minecraft.log2",
        "mineBlock.minecraft.magma",
        "mineBlock.minecraft.melon_block",
        "mineBlock.minecraft.monster_egg",
        "mineBlock.minecraft.mossy_cobblestone",
        "mineBlock.minecraft.mycelium",
        "mineBlock.minecraft.nether_brick",
        "mineBlock.minecraft.nether_brick_fence",
        "mineBlock.minecraft.nether_brick_stairs",
        "mineBlock.minecraft.nether_wart_block",
        "mineBlock.minecraft.netherrack",
        "mineBlock.minecraft.noteblock",
        "mineBlock.minecraft.oak_stairs",
        "mineBlock.minecraft.observer",
        "mineBlock.minecraft.obsidian",
        "mineBlock.minecraft.orange_glazed_terracotta",
        "mineBlock.minecraft.packed_ice",
        "mineBlock.minecraft.pink_glazed_terracotta",
        "mineBlock.minecraft.piston",
        "mineBlock.minecraft.planks",
        "mineBlock.minecraft.prismarine",
        "mineBlock.minecraft.pumpkin",
        "mineBlock.minecraft.purple_glazed_terracotta",
        "mineBlock.minecraft.purpur_block",
        "mineBlock.minecraft.purpur_pillar",
        "mineBlock.minecraft.purpur_slab",
        "mineBlock.minecraft.purpur_stairs",
        "mineBlock.minecraft.quartz_block",
        "mineBlock.minecraft.quartz_ore",
        "mineBlock.minecraft.quartz_stairs",
        "mineBlock.minecraft.rail",
        "mineBlock.minecraft.red_flower",
        "mineBlock.minecraft.red_glazed_terracotta",
        "mineBlock.minecraft.red_mushroom",
        "mineBlock.minecraft.red_mushroom_block",
        "mineBlock.minecraft.red_nether_brick",
        "mineBlock.minecraft.red_sandstone",
        "mineBlock.minecraft.red_sandstone_stairs",
        "mineBlock.minecraft.redstone_block",
        "mineBlock.minecraft.redstone_lamp",
        "mineBlock.minecraft.redstone_ore",
        "mineBlock.minecraft.redstone_torch",
        "mineBlock.minecraft.sand",
        "mineBlock.minecraft.sandstone",
        "mineBlock.minecraft.sandstone_stairs",
        "mineBlock.minecraft.sapling",
        "mineBlock.minecraft.sea_lantern",
        "mineBlock.minecraft.silver_glazed_terracotta",
        "mineBlock.minecraft.slime",
        "mineBlock.minecraft.snow",
        "mineBlock.minecraft.snow_layer",
        "mineBlock.minecraft.soul_sand",
        "mineBlock.minecraft.sponge",
        "mineBlock.minecraft.spruce_fence",
        "mineBlock.minecraft.spruce_fence_gate",
        "mineBlock.minecraft.spruce_stairs",
        "mineBlock.minecraft.stained_glass",
        "mineBlock.minecraft.stained_glass_pane",
        "mineBlock.minecraft.stained_hardened_clay",
        "mineBlock.minecraft.sticky_piston",
        "mineBlock.minecraft.stone",
        "mineBlock.minecraft.stone_brick_stairs",
        "mineBlock.minecraft.stone_button",
        "mineBlock.minecraft.stone_pressure_plate",
        "mineBlock.minecraft.stone_slab",
        "mineBlock.minecraft.stone_stairs",
        "mineBlock.minecraft.stonebrick",
        "mineBlock.minecraft.tallgrass",
        "mineBlock.minecraft.tnt",
        "mineBlock.minecraft.torch",
        "mineBlock.minecraft.trapped_chest",
        "mineBlock.minecraft.tripwire_hook",
        "mineBlock.minecraft.vine",
        "mineBlock.minecraft.waterlily",
        "mineBlock.minecraft.web",
        "mineBlock.minecraft.white_glazed_terracotta",
        "mineBlock.minecraft.wooden_button",
        "mineBlock.minecraft.wooden_pressure_plate",
        "mineBlock.minecraft.wooden_slab",
        "mineBlock.minecraft.wool",
        "mineBlock.minecraft.yellow_flower",
        "mineBlock.minecraft.yellow_glazed_terracotta",
        "minecartOneCm",
        "mobKills",
        "noteblockPlayed",
        "noteblockTuned",
        "pickup.minecraft.acacia_boat",
        "pickup.minecraft.acacia_door",
        "pickup.minecraft.acacia_fence",
        "pickup.minecraft.acacia_fence_gate",
        "pickup.minecraft.acacia_stairs",
        "pickup.minecraft.activator_rail",
        "pickup.minecraft.anvil",
        "pickup.minecraft.apple",
        "pickup.minecraft.armor_stand",
        "pickup.minecraft.arrow",
        "pickup.minecraft.baked_potato",
        "pickup.minecraft.banner",
        "pickup.minecraft.beacon",
        "pickup.minecraft.bed",
        "pickup.minecraft.bedrock",
        "pickup.minecraft.beef",
        "pickup.minecraft.beetroot",
        "pickup.minecraft.beetroot_seeds",
        "pickup.minecraft.beetroot_soup",
        "pickup.minecraft.birch_boat",
        "pickup.minecraft.birch_door",
        "pickup.minecraft.birch_fence",
        "pickup.minecraft.birch_fence_gate",
        "pickup.minecraft.birch_stairs",
        "pickup.minecraft.black_glazed_terracotta",
        "pickup.minecraft.black_shulker_box",
        "pickup.minecraft.blaze_powder",
        "pickup.minecraft.blaze_rod",
        "pickup.minecraft.blue_glazed_terracotta",
        "pickup.minecraft.blue_shulker_box",
        "pickup.minecraft.boat",
        "pickup.minecraft.bone",
        "pickup.minecraft.bone_block",
        "pickup.minecraft.book",
        "pickup.minecraft.bookshelf",
        "pickup.minecraft.bow",
        "pickup.minecraft.bowl",
        "pickup.minecraft.bread",
        "pickup.minecraft.brewing_stand",
        "pickup.minecraft.brick",
        "pickup.minecraft.brick_block",
        "pickup.minecraft.brick_stairs",
        "pickup.minecraft.brown_glazed_terracotta",
        "pickup.minecraft.brown_mushroom",
        "pickup.minecraft.brown_mushroom_block",
        "pickup.minecraft.brown_shulker_box",
        "pickup.minecraft.bucket",
        "pickup.minecraft.cactus",
        "pickup.minecraft.cake",
        "pickup.minecraft.carpet",
        "pickup.minecraft.carrot",
        "pickup.minecraft.carrot_on_a_stick",
        "pickup.minecraft.cauldron",
        "pickup.minecraft.chainmail_boots",
        "pickup.minecraft.chainmail_chestplate",
        "pickup.minecraft.chainmail_helmet",
        "pickup.minecraft.chainmail_leggings",
        "pickup.minecraft.chest",
        "pickup.minecraft.chest_minecart",
        "pickup.minecraft.chicken",
        "pickup.minecraft.chorus_flower",
        "pickup.minecraft.chorus_fruit",
        "pickup.minecraft.chorus_fruit_popped",
        "pickup.minecraft.clay",
        "pickup.minecraft.clay_ball",
        "pickup.minecraft.clock",
        "pickup.minecraft.coal",
        "pickup.minecraft.coal_block",
        "pickup.minecraft.coal_ore",
        "pickup.minecraft.cobblestone",
        "pickup.minecraft.cobblestone_wall",
        "pickup.minecraft.comparator",
        "pickup.minecraft.compass",
        "pickup.minecraft.concrete",
        "pickup.minecraft.concrete_powder",
        "pickup.minecraft.cooked_beef",
        "pickup.minecraft.cooked_chicken",
        "pickup.minecraft.cooked_fish",
        "pickup.minecraft.cooked_mutton",
        "pickup.minecraft.cooked_porkchop",
        "pickup.minecraft.cooked_rabbit",
        "pickup.minecraft.cookie",
        "pickup.minecraft.crafting_table",
        "pickup.minecraft.cyan_glazed_terracotta",
        "pickup.minecraft.cyan_shulker_box",
        "pickup.minecraft.dark_oak_boat",
        "pickup.minecraft.dark_oak_door",
        "pickup.minecraft.dark_oak_fence",
        "pickup.minecraft.dark_oak_fence_gate",
        "pickup.minecraft.dark_oak_stairs",
        "pickup.minecraft.daylight_detector",
        "pickup.minecraft.deadbush",
        "pickup.minecraft.detector_rail",
        "pickup.minecraft.diamond",
        "pickup.minecraft.diamond_axe",
        "pickup.minecraft.diamond_block",
        "pickup.minecraft.diamond_boots",
        "pickup.minecraft.diamond_chestplate",
        "pickup.minecraft.diamond_helmet",
        "pickup.minecraft.diamond_hoe",
        "pickup.minecraft.diamond_horse_armor",
        "pickup.minecraft.diamond_leggings",
        "pickup.minecraft.diamond_ore",
        "pickup.minecraft.diamond_pickaxe",
        "pickup.minecraft.diamond_shovel",
        "pickup.minecraft.diamond_sword",
        "pickup.minecraft.dirt",
        "pickup.minecraft.dispenser",
        "pickup.minecraft.double_plant",
        "pickup.minecraft.dragon_breath",
        "pickup.minecraft.dragon_egg",
        "pickup.minecraft.dropper",
        "pickup.minecraft.dye",
        "pickup.minecraft.egg",
        "pickup.minecraft.elytra",
        "pickup.minecraft.emerald",
        "pickup.minecraft.emerald_block",
        "pickup.minecraft.emerald_ore",
        "pickup.minecraft.enchanted_book",
        "pickup.minecraft.enchanting_table",
        "pickup.minecraft.end_bricks",
        "pickup.minecraft.end_crystal",
        "pickup.minecraft.end_rod",
        "pickup.minecraft.end_stone",
        "pickup.minecraft.ender_chest",
        "pickup.minecraft.ender_eye",
        "pickup.minecraft.ender_pearl",
        "pickup.minecraft.experience_bottle",
        "pickup.minecraft.feather",
        "pickup.minecraft.fence",
        "pickup.minecraft.fence_gate",
        "pickup.minecraft.fermented_spider_eye",
        "pickup.minecraft.filled_map",
        "pickup.minecraft.fire_charge",
        "pickup.minecraft.fireworks",
        "pickup.minecraft.fish",
        "pickup.minecraft.fishing_rod",
        "pickup.minecraft.flint",
        "pickup.minecraft.flint_and_steel",
        "pickup.minecraft.flower_pot",
        "pickup.minecraft.furnace",
        "pickup.minecraft.furnace_minecart",
        "pickup.minecraft.ghast_tear",
        "pickup.minecraft.glass",
        "pickup.minecraft.glass_bottle",
        "pickup.minecraft.glass_pane",
        "pickup.minecraft.glowstone",
        "pickup.minecraft.glowstone_dust",
        "pickup.minecraft.gold_block",
        "pickup.minecraft.gold_ingot",
        "pickup.minecraft.gold_nugget",
        "pickup.minecraft.gold_ore",
        "pickup.minecraft.golden_apple",
        "pickup.minecraft.golden_axe",
        "pickup.minecraft.golden_boots",
        "pickup.minecraft.golden_carrot",
        "pickup.minecraft.golden_chestplate",
        "pickup.minecraft.golden_helmet",
        "pickup.minecraft.golden_hoe",
        "pickup.minecraft.golden_horse_armor",
        "pickup.minecraft.golden_leggings",
        "pickup.minecraft.golden_pickaxe",
        "pickup.minecraft.golden_rail",
        "pickup.minecraft.golden_shovel",
        "pickup.minecraft.golden_sword",
        "pickup.minecraft.grass",
        "pickup.minecraft.gravel",
        "pickup.minecraft.gray_glazed_terracotta",
        "pickup.minecraft.gray_shulker_box",
        "pickup.minecraft.green_glazed_terracotta",
        "pickup.minecraft.green_shulker_box",
        "pickup.minecraft.gunpowder",
        "pickup.minecraft.hardened_clay",
        "pickup.minecraft.hay_block",
        "pickup.minecraft.heavy_weighted_pressure_plate",
        "pickup.minecraft.hopper",
        "pickup.minecraft.hopper_minecart",
        "pickup.minecraft.ice",
        "pickup.minecraft.iron_axe",
        "pickup.minecraft.iron_bars",
        "pickup.minecraft.iron_block",
        "pickup.minecraft.iron_boots",
        "pickup.minecraft.iron_chestplate",
        "pickup.minecraft.iron_door",
        "pickup.minecraft.iron_helmet",
        "pickup.minecraft.iron_hoe",
        "pickup.minecraft.iron_horse_armor",
        "pickup.minecraft.iron_ingot",
        "pickup.minecraft.iron_leggings",
        "pickup.minecraft.iron_nugget",
        "pickup.minecraft.iron_ore",
        "pickup.minecraft.iron_pickaxe",
        "pickup.minecraft.iron_shovel",
        "pickup.minecraft.iron_sword",
        "pickup.minecraft.iron_trapdoor",
        "pickup.minecraft.item_frame",
        "pickup.minecraft.jukebox",
        "pickup.minecraft.jungle_boat",
        "pickup.minecraft.jungle_door",
        "pickup.minecraft.jungle_fence",
        "pickup.minecraft.jungle_fence_gate",
        "pickup.minecraft.jungle_stairs",
        "pickup.minecraft.ladder",
        "pickup.minecraft.lapis_block",
        "pickup.minecraft.lapis_ore",
        "pickup.minecraft.lava_bucket",
        "pickup.minecraft.lead",
        "pickup.minecraft.leather",
        "pickup.minecraft.leather_boots",
        "pickup.minecraft.leather_chestplate",
        "pickup.minecraft.leather_helmet",
        "pickup.minecraft.leather_leggings",
        "pickup.minecraft.leaves",
        "pickup.minecraft.leaves2",
        "pickup.minecraft.lever",
        "pickup.minecraft.light_blue_glazed_terracotta",
        "pickup.minecraft.light_blue_shulker_box",
        "pickup.minecraft.light_weighted_pressure_plate",
        "pickup.minecraft.lime_glazed_terracotta",
        "pickup.minecraft.lime_shulker_box",
        "pickup.minecraft.lingering_potion",
        "pickup.minecraft.lit_pumpkin",
        "pickup.minecraft.log",
        "pickup.minecraft.log2",
        "pickup.minecraft.magenta_glazed_terracotta",
        "pickup.minecraft.magenta_shulker_box",
        "pickup.minecraft.magma",
        "pickup.minecraft.magma_cream",
        "pickup.minecraft.map",
        "pickup.minecraft.melon",
        "pickup.minecraft.melon_block",
        "pickup.minecraft.melon_seeds",
        "pickup.minecraft.milk_bucket",
        "pickup.minecraft.minecart",
        "pickup.minecraft.mossy_cobblestone",
        "pickup.minecraft.mushroom_stew",
        "pickup.minecraft.mutton",
        "pickup.minecraft.mycelium",
        "pickup.minecraft.name_tag",
        "pickup.minecraft.nether_brick",
        "pickup.minecraft.nether_brick_fence",
        "pickup.minecraft.nether_brick_stairs",
        "pickup.minecraft.nether_star",
        "pickup.minecraft.nether_wart",
        "pickup.minecraft.nether_wart_block",
        "pickup.minecraft.netherbrick",
        "pickup.minecraft.netherrack",
        "pickup.minecraft.noteblock",
        "pickup.minecraft.oak_stairs",
        "pickup.minecraft.observer",
        "pickup.minecraft.obsidian",
        "pickup.minecraft.orange_glazed_terracotta",
        "pickup.minecraft.orange_shulker_box",
        "pickup.minecraft.packed_ice",
        "pickup.minecraft.painting",
        "pickup.minecraft.paper",
        "pickup.minecraft.pink_glazed_terracotta",
        "pickup.minecraft.pink_shulker_box",
        "pickup.minecraft.piston",
        "pickup.minecraft.planks",
        "pickup.minecraft.poisonous_potato",
        "pickup.minecraft.porkchop",
        "pickup.minecraft.potato",
        "pickup.minecraft.potion",
        "pickup.minecraft.prismarine",
        "pickup.minecraft.prismarine_crystals",
        "pickup.minecraft.prismarine_shard",
        "pickup.minecraft.pumpkin",
        "pickup.minecraft.pumpkin_pie",
        "pickup.minecraft.pumpkin_seeds",
        "pickup.minecraft.purple_glazed_terracotta",
        "pickup.minecraft.purple_shulker_box",
        "pickup.minecraft.purpur_block",
        "pickup.minecraft.purpur_pillar",
        "pickup.minecraft.purpur_slab",
        "pickup.minecraft.purpur_stairs",
        "pickup.minecraft.quartz",
        "pickup.minecraft.quartz_block",
        "pickup.minecraft.quartz_ore",
        "pickup.minecraft.quartz_stairs",
        "pickup.minecraft.rabbit",
        "pickup.minecraft.rabbit_foot",
        "pickup.minecraft.rabbit_hide",
        "pickup.minecraft.rail",
        "pickup.minecraft.record_11",
        "pickup.minecraft.record_13",
        "pickup.minecraft.record_blocks",
        "pickup.minecraft.record_cat",
        "pickup.minecraft.record_chirp",
        "pickup.minecraft.record_far",
        "pickup.minecraft.record_mall",
        "pickup.minecraft.record_mellohi",
        "pickup.minecraft.record_stal",
        "pickup.minecraft.record_strad",
        "pickup.minecraft.record_wait",
        "pickup.minecraft.record_ward",
        "pickup.minecraft.red_flower",
        "pickup.minecraft.red_glazed_terracotta",
        "pickup.minecraft.red_mushroom",
        "pickup.minecraft.red_mushroom_block",
        "pickup.minecraft.red_nether_brick",
        "pickup.minecraft.red_sandstone",
        "pickup.minecraft.red_sandstone_stairs",
        "pickup.minecraft.red_shulker_box",
        "pickup.minecraft.redstone",
        "pickup.minecraft.redstone_block",
        "pickup.minecraft.redstone_lamp",
        "pickup.minecraft.redstone_ore",
        "pickup.minecraft.redstone_torch",
        "pickup.minecraft.reeds",
        "pickup.minecraft.repeater",
        "pickup.minecraft.rotten_flesh",
        "pickup.minecraft.saddle",
        "pickup.minecraft.sand",
        "pickup.minecraft.sandstone",
        "pickup.minecraft.sandstone_stairs",
        "pickup.minecraft.sapling",
        "pickup.minecraft.sea_lantern",
        "pickup.minecraft.shears",
        "pickup.minecraft.shield",
        "pickup.minecraft.shulker_shell",
        "pickup.minecraft.sign",
        "pickup.minecraft.silver_glazed_terracotta",
        "pickup.minecraft.silver_shulker_box",
        "pickup.minecraft.skull",
        "pickup.minecraft.slime",
        "pickup.minecraft.slime_ball",
        "pickup.minecraft.snow",
        "pickup.minecraft.snow_layer",
        "pickup.minecraft.snowball",
        "pickup.minecraft.soul_sand",
        "pickup.minecraft.speckled_melon",
        "pickup.minecraft.spectral_arrow",
        "pickup.minecraft.spider_eye",
        "pickup.minecraft.splash_potion",
        "pickup.minecraft.sponge",
        "pickup.minecraft.spruce_boat",
        "pickup.minecraft.spruce_door",
        "pickup.minecraft.spruce_fence",
        "pickup.minecraft.spruce_fence_gate",
        "pickup.minecraft.spruce_stairs",
        "pickup.minecraft.stained_glass",
        "pickup.minecraft.stained_glass_pane",
        "pickup.minecraft.stained_hardened_clay",
        "pickup.minecraft.stick",
        "pickup.minecraft.sticky_piston",
        "pickup.minecraft.stone",
        "pickup.minecraft.stone_axe",
        "pickup.minecraft.stone_brick_stairs",
        "pickup.minecraft.stone_button",
        "pickup.minecraft.stone_hoe",
        "pickup.minecraft.stone_pickaxe",
        "pickup.minecraft.stone_pressure_plate",
        "pickup.minecraft.stone_shovel",
        "pickup.minecraft.stone_slab",
        "pickup.minecraft.stone_stairs",
        "pickup.minecraft.stone_sword",
        "pickup.minecraft.stonebrick",
        "pickup.minecraft.string",
        "pickup.minecraft.sugar",
        "pickup.minecraft.tallgrass",
        "pickup.minecraft.tipped_arrow",
        "pickup.minecraft.tnt",
        "pickup.minecraft.tnt_minecart",
        "pickup.minecraft.torch",
        "pickup.minecraft.totem_of_undying",
        "pickup.minecraft.trapdoor",
        "pickup.minecraft.trapped_chest",
        "pickup.minecraft.tripwire_hook",
        "pickup.minecraft.vine",
        "pickup.minecraft.water_bucket",
        "pickup.minecraft.waterlily",
        "pickup.minecraft.web",
        "pickup.minecraft.wheat",
        "pickup.minecraft.wheat_seeds",
        "pickup.minecraft.white_glazed_terracotta",
        "pickup.minecraft.white_shulker_box",
        "pickup.minecraft.wooden_axe",
        "pickup.minecraft.wooden_button",
        "pickup.minecraft.wooden_door",
        "pickup.minecraft.wooden_hoe",
        "pickup.minecraft.wooden_pickaxe",
        "pickup.minecraft.wooden_pressure_plate",
        "pickup.minecraft.wooden_shovel",
        "pickup.minecraft.wooden_slab",
        "pickup.minecraft.wooden_sword",
        "pickup.minecraft.wool",
        "pickup.minecraft.writable_book",
        "pickup.minecraft.written_book",
        "pickup.minecraft.yellow_flower",
        "pickup.minecraft.yellow_glazed_terracotta",
        "pickup.minecraft.yellow_shulker_box",
        "pigOneCm",
        "playOneMinute",
        "playerKills",
        "shulkerBoxOpened",
        "sleepInBed",
        "sneakTime",
        "sprintOneCm",
        "swimOneCm",
        "talkedToVillager",
        "timeSinceDeath",
        "tradedWithVillager",
        "trappedChestTriggered",
        "useItem.minecraft.acacia_boat",
        "useItem.minecraft.acacia_door",
        "useItem.minecraft.acacia_fence",
        "useItem.minecraft.acacia_fence_gate",
        "useItem.minecraft.acacia_stairs",
        "useItem.minecraft.activator_rail",
        "useItem.minecraft.anvil",
        "useItem.minecraft.apple",
        "useItem.minecraft.armor_stand",
        "useItem.minecraft.baked_potato",
        "useItem.minecraft.banner",
        "useItem.minecraft.barrier",
        "useItem.minecraft.beacon",
        "useItem.minecraft.bed",
        "useItem.minecraft.bedrock",
        "useItem.minecraft.beef",
        "useItem.minecraft.beetroot",
        "useItem.minecraft.beetroot_seeds",
        "useItem.minecraft.beetroot_soup",
        "useItem.minecraft.birch_boat",
        "useItem.minecraft.birch_door",
        "useItem.minecraft.birch_fence",
        "useItem.minecraft.birch_fence_gate",
        "useItem.minecraft.birch_stairs",
        "useItem.minecraft.black_glazed_terracotta",
        "useItem.minecraft.black_shulker_box",
        "useItem.minecraft.blue_glazed_terracotta",
        "useItem.minecraft.blue_shulker_box",
        "useItem.minecraft.boat",
        "useItem.minecraft.bone_block",
        "useItem.minecraft.bookshelf",
        "useItem.minecraft.bow",
        "useItem.minecraft.bread",
        "useItem.minecraft.brewing_stand",
        "useItem.minecraft.brick_block",
        "useItem.minecraft.brick_stairs",
        "useItem.minecraft.brown_glazed_terracotta",
        "useItem.minecraft.brown_mushroom",
        "useItem.minecraft.brown_mushroom_block",
        "useItem.minecraft.brown_shulker_box",
        "useItem.minecraft.bucket",
        "useItem.minecraft.cactus",
        "useItem.minecraft.cake",
        "useItem.minecraft.carpet",
        "useItem.minecraft.carrot",
        "useItem.minecraft.carrot_on_a_stick",
        "useItem.minecraft.cauldron",
        "useItem.minecraft.chest",
        "useItem.minecraft.chest_minecart",
        "useItem.minecraft.chicken",
        "useItem.minecraft.chorus_flower",
        "useItem.minecraft.chorus_fruit",
        "useItem.minecraft.clay",
        "useItem.minecraft.coal_block",
        "useItem.minecraft.coal_ore",
        "useItem.minecraft.cobblestone",
        "useItem.minecraft.cobblestone_wall",
        "useItem.minecraft.command_block",
        "useItem.minecraft.comparator",
        "useItem.minecraft.concrete",
        "useItem.minecraft.concrete_powder",
        "useItem.minecraft.cooked_beef",
        "useItem.minecraft.cooked_chicken",
        "useItem.minecraft.cooked_fish",
        "useItem.minecraft.cooked_mutton",
        "useItem.minecraft.cooked_porkchop",
        "useItem.minecraft.cooked_rabbit",
        "useItem.minecraft.cookie",
        "useItem.minecraft.crafting_table",
        "useItem.minecraft.cyan_shulker_box",
        "useItem.minecraft.dark_oak_boat",
        "useItem.minecraft.dark_oak_door",
        "useItem.minecraft.dark_oak_fence",
        "useItem.minecraft.dark_oak_fence_gate",
        "useItem.minecraft.dark_oak_stairs",
        "useItem.minecraft.daylight_detector",
        "useItem.minecraft.deadbush",
        "useItem.minecraft.detector_rail",
        "useItem.minecraft.diamond_axe",
        "useItem.minecraft.diamond_block",
        "useItem.minecraft.diamond_hoe",
        "useItem.minecraft.diamond_ore",
        "useItem.minecraft.diamond_pickaxe",
        "useItem.minecraft.diamond_shovel",
        "useItem.minecraft.diamond_sword",
        "useItem.minecraft.dirt",
        "useItem.minecraft.dispenser",
        "useItem.minecraft.double_plant",
        "useItem.minecraft.dragon_egg",
        "useItem.minecraft.dropper",
        "useItem.minecraft.dye",
        "useItem.minecraft.egg",
        "useItem.minecraft.emerald_block",
        "useItem.minecraft.emerald_ore",
        "useItem.minecraft.enchanting_table",
        "useItem.minecraft.end_bricks",
        "useItem.minecraft.end_crystal",
        "useItem.minecraft.end_portal_frame",
        "useItem.minecraft.end_rod",
        "useItem.minecraft.end_stone",
        "useItem.minecraft.ender_chest",
        "useItem.minecraft.ender_eye",
        "useItem.minecraft.ender_pearl",
        "useItem.minecraft.experience_bottle",
        "useItem.minecraft.fence",
        "useItem.minecraft.fence_gate",
        "useItem.minecraft.fire_charge",
        "useItem.minecraft.fireworks",
        "useItem.minecraft.fish",
        "useItem.minecraft.fishing_rod",
        "useItem.minecraft.flint_and_steel",
        "useItem.minecraft.flower_pot",
        "useItem.minecraft.furnace",
        "useItem.minecraft.furnace_minecart",
        "useItem.minecraft.glass",
        "useItem.minecraft.glass_bottle",
        "useItem.minecraft.glass_pane",
        "useItem.minecraft.glowstone",
        "useItem.minecraft.gold_block",
        "useItem.minecraft.gold_ore",
        "useItem.minecraft.golden_apple",
        "useItem.minecraft.golden_axe",
        "useItem.minecraft.golden_carrot",
        "useItem.minecraft.golden_hoe",
        "useItem.minecraft.golden_pickaxe",
        "useItem.minecraft.golden_rail",
        "useItem.minecraft.golden_shovel",
        "useItem.minecraft.golden_sword",
        "useItem.minecraft.gravel",
        "useItem.minecraft.gray_glazed_terracotta",
        "useItem.minecraft.gray_shulker_box",
        "useItem.minecraft.green_glazed_terracotta",
        "useItem.minecraft.green_shulker_box",
        "useItem.minecraft.hardened_clay",
        "useItem.minecraft.hay_block",
        "useItem.minecraft.heavy_weighted_pressure_plate",
        "useItem.minecraft.hopper",
        "useItem.minecraft.hopper_minecart",
        "useItem.minecraft.ice",
        "useItem.minecraft.iron_axe",
        "useItem.minecraft.iron_bars",
        "useItem.minecraft.iron_block",
        "useItem.minecraft.iron_door",
        "useItem.minecraft.iron_hoe",
        "useItem.minecraft.iron_ore",
        "useItem.minecraft.iron_pickaxe",
        "useItem.minecraft.iron_shovel",
        "useItem.minecraft.iron_sword",
        "useItem.minecraft.iron_trapdoor",
        "useItem.minecraft.item_frame",
        "useItem.minecraft.jukebox",
        "useItem.minecraft.jungle_boat",
        "useItem.minecraft.jungle_door",
        "useItem.minecraft.jungle_fence",
        "useItem.minecraft.jungle_fence_gate",
        "useItem.minecraft.jungle_stairs",
        "useItem.minecraft.ladder",
        "useItem.minecraft.lapis_block",
        "useItem.minecraft.lapis_ore",
        "useItem.minecraft.lava_bucket",
        "useItem.minecraft.lead",
        "useItem.minecraft.leaves",
        "useItem.minecraft.leaves2",
        "useItem.minecraft.lever",
        "useItem.minecraft.light_blue_glazed_terracotta",
        "useItem.minecraft.light_blue_shulker_box",
        "useItem.minecraft.light_weighted_pressure_plate",
        "useItem.minecraft.lime_glazed_terracotta",
        "useItem.minecraft.lime_shulker_box",
        "useItem.minecraft.lingering_potion",
        "useItem.minecraft.log",
        "useItem.minecraft.log2",
        "useItem.minecraft.magenta_glazed_terracotta",
        "useItem.minecraft.magenta_shulker_box",
        "useItem.minecraft.magma",
        "useItem.minecraft.map",
        "useItem.minecraft.melon",
        "useItem.minecraft.melon_block",
        "useItem.minecraft.melon_seeds",
        "useItem.minecraft.milk_bucket",
        "useItem.minecraft.minecart",
        "useItem.minecraft.monster_egg",
        "useItem.minecraft.mossy_cobblestone",
        "useItem.minecraft.mushroom_stew",
        "useItem.minecraft.mutton",
        "useItem.minecraft.mycelium",
        "useItem.minecraft.nether_brick",
        "useItem.minecraft.nether_brick_fence",
        "useItem.minecraft.nether_brick_stairs",
        "useItem.minecraft.nether_wart",
        "useItem.minecraft.nether_wart_block",
        "useItem.minecraft.netherrack",
        "useItem.minecraft.noteblock",
        "useItem.minecraft.oak_stairs",
        "useItem.minecraft.observer",
        "useItem.minecraft.obsidian",
        "useItem.minecraft.orange_glazed_terracotta",
        "useItem.minecraft.orange_shulker_box",
        "useItem.minecraft.packed_ice",
        "useItem.minecraft.painting",
        "useItem.minecraft.pink_glazed_terracotta",
        "useItem.minecraft.pink_shulker_box",
        "useItem.minecraft.piston",
        "useItem.minecraft.planks",
        "useItem.minecraft.poisonous_potato",
        "useItem.minecraft.porkchop",
        "useItem.minecraft.potato",
        "useItem.minecraft.potion",
        "useItem.minecraft.prismarine",
        "useItem.minecraft.pumpkin",
        "useItem.minecraft.pumpkin_pie",
        "useItem.minecraft.pumpkin_seeds",
        "useItem.minecraft.purple_glazed_terracotta",
        "useItem.minecraft.purple_shulker_box",
        "useItem.minecraft.purpur_block",
        "useItem.minecraft.purpur_pillar",
        "useItem.minecraft.purpur_slab",
        "useItem.minecraft.purpur_stairs",
        "useItem.minecraft.quartz_block",
        "useItem.minecraft.quartz_ore",
        "useItem.minecraft.quartz_stairs",
        "useItem.minecraft.rabbit",
        "useItem.minecraft.rabbit_stew",
        "useItem.minecraft.rail",
        "useItem.minecraft.record_11",
        "useItem.minecraft.record_13",
        "useItem.minecraft.record_blocks",
        "useItem.minecraft.record_cat",
        "useItem.minecraft.record_chirp",
        "useItem.minecraft.record_far",
        "useItem.minecraft.record_mall",
        "useItem.minecraft.record_mellohi",
        "useItem.minecraft.record_stal",
        "useItem.minecraft.record_strad",
        "useItem.minecraft.record_wait",
        "useItem.minecraft.record_ward",
        "useItem.minecraft.red_flower",
        "useItem.minecraft.red_glazed_terracotta",
        "useItem.minecraft.red_mushroom",
        "useItem.minecraft.red_mushroom_block",
        "useItem.minecraft.red_nether_brick",
        "useItem.minecraft.red_sandstone",
        "useItem.minecraft.red_sandstone_stairs",
        "useItem.minecraft.red_shulker_box",
        "useItem.minecraft.redstone",
        "useItem.minecraft.redstone_block",
        "useItem.minecraft.redstone_lamp",
        "useItem.minecraft.redstone_ore",
        "useItem.minecraft.redstone_torch",
        "useItem.minecraft.reeds",
        "useItem.minecraft.repeater",
        "useItem.minecraft.rotten_flesh",
        "useItem.minecraft.sand",
        "useItem.minecraft.sandstone",
        "useItem.minecraft.sandstone_stairs",
        "useItem.minecraft.sapling",
        "useItem.minecraft.sea_lantern",
        "useItem.minecraft.shears",
        "useItem.minecraft.sign",
        "useItem.minecraft.silver_glazed_terracotta",
        "useItem.minecraft.silver_shulker_box",
        "useItem.minecraft.skull",
        "useItem.minecraft.slime",
        "useItem.minecraft.snow",
        "useItem.minecraft.snow_layer",
        "useItem.minecraft.snowball",
        "useItem.minecraft.soul_sand",
        "useItem.minecraft.spider_eye",
        "useItem.minecraft.splash_potion",
        "useItem.minecraft.sponge",
        "useItem.minecraft.spruce_boat",
        "useItem.minecraft.spruce_door",
        "useItem.minecraft.spruce_fence",
        "useItem.minecraft.spruce_fence_gate",
        "useItem.minecraft.spruce_stairs",
        "useItem.minecraft.stained_glass",
        "useItem.minecraft.stained_glass_pane",
        "useItem.minecraft.stained_hardened_clay",
        "useItem.minecraft.sticky_piston",
        "useItem.minecraft.stone",
        "useItem.minecraft.stone_axe",
        "useItem.minecraft.stone_brick_stairs",
        "useItem.minecraft.stone_button",
        "useItem.minecraft.stone_hoe",
        "useItem.minecraft.stone_pickaxe",
        "useItem.minecraft.stone_pressure_plate",
        "useItem.minecraft.stone_shovel",
        "useItem.minecraft.stone_slab",
        "useItem.minecraft.stone_slab2",
        "useItem.minecraft.stone_stairs",
        "useItem.minecraft.stone_sword",
        "useItem.minecraft.stonebrick",
        "useItem.minecraft.string",
        "useItem.minecraft.tallgrass",
        "useItem.minecraft.tnt",
        "useItem.minecraft.tnt_minecart",
        "useItem.minecraft.torch",
        "useItem.minecraft.totem_of_undying",
        "useItem.minecraft.trapdoor",
        "useItem.minecraft.trapped_chest",
        "useItem.minecraft.tripwire_hook",
        "useItem.minecraft.vine",
        "useItem.minecraft.water_bucket",
        "useItem.minecraft.waterlily",
        "useItem.minecraft.web",
        "useItem.minecraft.wheat_seeds",
        "useItem.minecraft.white_glazed_terracotta",
        "useItem.minecraft.white_shulker_box",
        "useItem.minecraft.wooden_axe",
        "useItem.minecraft.wooden_button",
        "useItem.minecraft.wooden_door",
        "useItem.minecraft.wooden_hoe",
        "useItem.minecraft.wooden_pickaxe",
        "useItem.minecraft.wooden_pressure_plate",
        "useItem.minecraft.wooden_shovel",
        "useItem.minecraft.wooden_slab",
        "useItem.minecraft.wooden_sword",
        "useItem.minecraft.wool",
        "useItem.minecraft.writable_book",
        "useItem.minecraft.written_book",
        "useItem.minecraft.yellow_flower",
        "useItem.minecraft.yellow_glazed_terracotta",
        "useItem.minecraft.yellow_shulker_box",
        "walkOneCm",
        "flyOneCm",
      ],
      statdata: [{ uuid: "", name: "", value: "" }],
      statname: "-",
      statorder: "DESC",
    }
    this.changestat = this.handlechangestat.bind(this)
    this.changeorder = this.handlechangeorder.bind(this)
  }

  updatedate() {
    fetch(
      `https://salcapi.n00bs.info/stats/stat.${this.state.statname}/${this.state.statorder}`
    )
      .then(response => {
        if (response.status === 200) {
          return response.json()
        }
        throw new Error("Unknown error: " + String(response.status))
      })
      .then(json => {
        this.setState({
          statdata: json,
        })
      })
  }

  handlechangestat(value) {
    this.setState({ statname: value.target.value }, () => {
      if (this.state.statlist.includes(this.state.statname)) {
        this.updatedate()
      }
      return true
    })
  }

  handlechangeorder(value) {
    this.setState({ statorder: value.target.value }, () => {
      if (this.state.statlist.includes(this.state.statname)) {
        this.updatedate()
      }
      return true
    })
  }

  render() {
    return (
      <Layout>
        <div className="text-center">
          <input
            className="text-black min-w-[200px] w-1/4 text-center"
            name="stat"
            list="stats"
            autoComplete="on"
            onChange={this.changestat}
            placeholder="Enter stat here"
          />
          <datalist id="stats">
            {this.state.statlist.map((value, pos) => {
              return <option key={pos}>{value}</option>
            })}
          </datalist>
          <p>
            <input
              name="sort"
              type="radio"
              value="DESC"
              defaultChecked
              onChange={this.changeorder}
            />
            high -&gt; low
            <br />
          </p>
          <p>
            <input
              name="sort"
              type="radio"
              value="ASC"
              onChange={this.changeorder}
            />
            low -&gt; high
            <br />
          </p>

          <div>
            <table className="m-auto">
              <thead>
                <tr>
                  <th className="p-2">UUID</th>
                  <th className="p-2">NAME</th>
                  <th className="p-2">VALUE</th>
                </tr>
              </thead>
              <tbody>
                {this.state.statdata.slice(0, 500).map((player, pos) => {
                  return (
                    <tr key={pos}>
                      <td className="p-2">{player.uuid}</td>
                      <td className="p-2">{player.name}</td>
                      <td className="p-2">{player.value}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Layout>
    )
  }
}
